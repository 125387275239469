<script setup lang="ts">
import QCardHeaderSection from '~/components/ui/QCardHeaderSection.vue'

const emit = defineEmits(['confirm', ...useDialogPluginComponent.emits])

const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()

function confirm() {
  emit('confirm')
  onDialogOK()
}
</script>

<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard class="w-[500px] max-w-full">
      <QCardHeaderSection
        :title="$t('Undo bull winner')"
        :on-close="() => onDialogCancel()"
      />
      <QCardActions align="between">
        <QBtn
          v-touch:tap.prevent="confirm"
          color="positive"
          size="lg"
          :label="$t('Yes')"
        />
        <QBtn
          v-touch:tap.prevent="() => onDialogCancel()"
          color="negative"
          size="lg"
          :label="$t('No')"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>
