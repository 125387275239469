import UndoBullWinnerModal from '~/components/scoreapp/UndoBullWinnerModal.vue'
import UndoLastThrowModal from '~/components/scoreapp/UndoLastThrowModal.vue'
import ConfirmFinishingThrowModal from '~/components/scoreapp/ConfirmFinishingThrowModal.vue'
import {useLocalStorage} from '@vueuse/core'
import {skipHydrate} from 'pinia'
import {
    calculateAverage,
    convertApiThrowsToLocal,
    getLegs,
    getMostThrowsInLeg,
    getWinningThrowForLeg
} from '~/services/DartsThrowHelper'
import {ulid} from 'ulid'
import type {Game} from '~/types'

export type QueueItem = {
    action: 'register' | 'update' | 'remove' | 'undo-bull-winner' | 'set-bull-winner',
    data: DartsThrow
    player?: SomePlayer
}

export enum GamePlayerSimple {
    HOME = 'home',
    VISITOR = 'visitor',
}

export type DartsThrow = {
    participant_id: number | string,
    player_id: number | string,
    leg: number,
    score: number,
    darts_used: number,
    finishing_throw: boolean,
    failed: boolean,
    ulid: string,
}
export type DartsThrowWithRemaining = DartsThrow & { remaining: number }

export type SinglePlayer = {
    participant_id: number | string,
    id: number | string,
    name: string,
}

export type Team = {
    participant_id: number | string,
    team_id: number | string,
    players: [SinglePlayer, SinglePlayer],
}

export type SomePlayer = SinglePlayer | Team

export type LocalGameStore = {
    bull_winner: GamePlayerSimple | null,
    homeTeam: SomePlayer,
    visitorTeam: SomePlayer,
    throws: DartsThrow[],
    writer: SomePlayer | null,
    starting_score: number,
    legs_to_play: number | null,
    legs_to_win: number | null,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isTeam(entity: any): entity is Team {
    return typeof entity?.team_id !== 'undefined'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSinglePlayer(entity: any): entity is SinglePlayer {
    return typeof entity?.id !== 'undefined'
}

export type LocalGameSettings = {
    showAverages: boolean,
}

export function convertApiGameToLocal(game: Game): LocalGameStore {
    const gameState: LocalGameStore = {} as LocalGameStore
    if (game.bull_winner !== null) {
        gameState.bull_winner = game.bull_winner === 1 ? GamePlayerSimple.HOME : GamePlayerSimple.VISITOR
    } else {
        gameState.bull_winner = null
    }
    gameState.homeTeam = {
        id: game.player1.id,
        participant_id: game.player1.id,
        name: game.is_online ? game.player1.nickname : game.player1.name,
    }
    gameState.visitorTeam = {
        id: game.player2.id,
        participant_id: game.player2.id,
        name: game.is_online ? game.player2.nickname : game.player2.name,
    }
    gameState.writer = {
        id: game.writer?.id,
        participant_id: game.writer?.id,
        name: game.writer?.name,
    }

    gameState.throws = convertApiThrowsToLocal(game.throws)
    gameState.starting_score = game.starting_score
    gameState.legs_to_win = game.legs_to_win
    gameState.legs_to_play = game.legs_to_play

    return gameState
}

export const localGameStore = defineStore('localGameStore', () => {
    const {t} = useI18n()
    const $q = useQuasar()

    const showScorePad = ref(true)

    const onlineGame = ref<Game | null>(null)
    const tabletGame = useLocalStorage<Game | null>(
        'localGameStoreTabletGame',
        null,
        {
            serializer: {
                read: (v: string | null) => v ? JSON.parse(v) : null,
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                write: (v: any) => JSON.stringify(v),
            },
        },
    )
    const throwQueue = useLocalStorage<QueueItem[]>('localGameStoreThrowQueue', [])

    const settingCanSetBullWinner = ref(true)
    const settingCanUndoBullWinner = ref(true)
    const settingCanCorrectScore = ref(true)
    const settingCanUndoLastThrow = ref(true)

    const onSync = ref<(() => void) | null>(null)
    const onGameCompleted = ref<(() => void) | null>(null)
    const onUpdateThrow = ref<((dartsThrow: DartsThrow) => void) | null>(null)
    const onUndoLastThrow = ref<((dartsThrow: DartsThrow) => void) | null>(null)
    const onScoreSubmit = ref<((dartsThrow: DartsThrow) => void) | null>(null)
    const onBullWinner = ref<((playerIndex: SomePlayer) => void) | null>(null)
    const onUndoBullWinner = ref<(() => void) | null>(null)
    const onReset = ref<(() => void) | null>(null)
    const state = useLocalStorage<LocalGameStore>('localGameStore', {} as LocalGameStore)
    const settings = useLocalStorage<LocalGameSettings>('localGameStoreSettings', {showAverages: false})
    const winner = ref<GamePlayerSimple | null>(null)
    const scorePadValue = ref<number | null>(null)
    const correctingScore = ref<DartsThrow | null>(null)

    const initialize = (value: LocalGameStore) => {
        state.value = {...value}
    }

    const sync = (value: LocalGameStore) => {
        state.value = {...value}
        if (onSync.value) {
            onSync.value()
        }
    }

    const isInitialized = computed(() => {
        return state.value.homeTeam !== undefined
    })

    const getCurrentLeg = computed((): number => {
        let highestLeg = 1
        state.value.throws.forEach((throwItem) => {
            if (throwItem.leg > highestLeg) {
                highestLeg = throwItem.leg
            }
        })

        if (getLegWinner(highestLeg)) {
            return highestLeg + 1
        }

        return highestLeg
    })

    const getLegWinner = (leg: number): null | SomePlayer => {
        const winningThrow = getWinningThrowForLeg(state.value.throws, leg)

        if (winningThrow) {
            if (winningThrow.participant_id === state.value.homeTeam.participant_id) {
                return state.value.homeTeam
            }
            return state.value.visitorTeam
        }
        return null
    }

    const getRemainingScoreFor = (player: GamePlayerSimple): number => {
        const playerParticipandId = getParticipantIdFor(player)
        const throwsInLeg = state.value.throws.filter((throwItem) => throwItem.leg === getCurrentLeg.value && throwItem.participant_id === playerParticipandId)

        return state.value.starting_score - throwsInLeg.reduce((acc, throwItem) => {
            return acc + throwItem.score
        }, 0)
    }

    const getPlayerSimpleForParticipantId = (participantId: number | string): GamePlayerSimple => {
        if (participantId === state.value.homeTeam.participant_id) {
            return GamePlayerSimple.HOME
        }
        if (participantId === state.value.visitorTeam.participant_id) {
            return GamePlayerSimple.VISITOR
        }

        throw new Error('Participant id not found')
    }

    const getParticipantIdFor = (player: GamePlayerSimple): number | string => {
        return player === GamePlayerSimple.HOME ? state.value.homeTeam.participant_id : state.value.visitorTeam.participant_id
    }

    const getLegsWonFor = (player: GamePlayerSimple): number => {
        const playerParticipandId = getParticipantIdFor(player)
        const finishingThrows = state.value.throws.filter((throwItem) =>
            throwItem.participant_id === playerParticipandId && throwItem.finishing_throw
        )
        return finishingThrows.length
    }

    const getCurrentLegThrowsFor = (player: GamePlayerSimple): DartsThrow[] => {
        return getThrowsFor(player).filter((throwItem) => throwItem.leg === getCurrentLeg.value)
    }

    const getGameAverage = (player: GamePlayerSimple): number | 0 => {
        const dartsThrows = getThrowsFor(player)
        const playerParticipantId = getParticipantIdFor(player)
        return calculateAverage(dartsThrows, playerParticipantId)
    }

    const getLegAverage = (leg: number, player: GamePlayerSimple): number | 0 => {
        const dartsThrows = getThrowsFor(player)
        const playerParticipantId = getParticipantIdFor(player)
        return calculateAverage(dartsThrows.slice().filter(throwItem => throwItem.leg === leg), playerParticipantId)
    }

    const getFirst9Average = (player: GamePlayerSimple): number | 0 => {
        const dartsThrows = getThrowsFor(player)
        const playerParticipantId = getParticipantIdFor(player)
        // We can just take the first 6 throws as that mean 3 for each player.
        const legs = getLegs(dartsThrows)
        const averages: number[] = []
        legs.forEach((leg) => {
            averages.push(calculateAverage(dartsThrows.slice().filter(throwItem => throwItem.leg === leg).slice(0, 6), playerParticipantId))
        })

        const sum = averages.reduce((acc, throwItem) => {
            return acc + throwItem
        }, 0)

        return sum / averages.length
    }

    const get180sFor = (player: GamePlayerSimple): number => {
        const dartsThrows = getThrowsFor(player)
        return dartsThrows.filter((throwItem) => throwItem.score === 180).length
    }

    const get140sFor = (player: GamePlayerSimple): number => {
        const dartsThrows = getThrowsFor(player)
        return dartsThrows.filter((throwItem) => throwItem.score === 140).length
    }

    const getHighCheckoutsFor = (player: GamePlayerSimple): DartsThrow[] => {
        const dartsThrows = getThrowsFor(player)
        return dartsThrows.filter((throwItem) => throwItem.score >= 100 && throwItem.finishing_throw)
    }

    const getThrowsFor = (player: GamePlayerSimple): DartsThrow[] => {
        const playerParticipandId = getParticipantIdFor(player)
        return state.value.throws.filter((throwItem) =>
            throwItem.participant_id === playerParticipandId
        )
    }

    const getDartsThrownFor = (player: GamePlayerSimple): number => {
        return state.value.throws.reduce((acc, throwItem) => {
            if (throwItem.participant_id === getParticipantIdFor(player)) {
                return acc + throwItem.darts_used
            }
            return acc
        }, 0)
    }

    const getPlayerFor = (player: GamePlayerSimple): SomePlayer => {
        if (player === GamePlayerSimple.HOME) {
            return state.value.homeTeam
        }
        return state.value.visitorTeam
    }

    const getPlayersFor = (player: GamePlayerSimple): SinglePlayer[] => {
        if (player === GamePlayerSimple.HOME) {
            if (isTeam(state.value.homeTeam)) {
                return state.value.homeTeam.players
            }
            return [state.value.homeTeam]
        } else {
            if (isTeam(state.value.visitorTeam)) {
                return state.value.visitorTeam.players
            }
            return [state.value.visitorTeam]
        }
    }

    const getPlayerNameFor = (player: GamePlayerSimple): string => {
        return player === GamePlayerSimple.HOME ? getHomePlayerName.value : getVisitorPlayerName.value
    }

    const getHomePlayerName = computed((): string => {
        if (isTeam(state.value.homeTeam)) {
            return state.value.homeTeam.players[0].name + ' - ' + state.value.homeTeam.players[1].name
        }

        return state.value.homeTeam.name
    })

    const getVisitorPlayerName = computed((): string => {
        if (isTeam(state.value.visitorTeam)) {
            return state.value.visitorTeam.players[0].name + ' - ' + state.value.visitorTeam.players[1].name
        }

        return state.value.visitorTeam.name
    })

    const getParticipantToThrow = computed((): SomePlayer => {
        const currentLeg = getCurrentLeg.value
        const throwsInCurrentLeg = state.value.throws.filter(throwItem => throwItem.leg === currentLeg)
        const lastThrow = throwsInCurrentLeg[throwsInCurrentLeg.length - 1]

        if (!state.value.bull_winner) {
            throw new Error(t('No bull winner'))
        }

        if (lastThrow) {
            if (state.value.homeTeam.participant_id === lastThrow.participant_id) {
                return state.value.visitorTeam
            }
            return state.value.homeTeam
        }

        // Get the leg starter.
        if (currentLeg % 2 === 0) {
            // Not the bull winner.
            return state.value.bull_winner === GamePlayerSimple.HOME ? state.value.visitorTeam : state.value.homeTeam
        }
        // The bull winner.
        return state.value.bull_winner === GamePlayerSimple.HOME ? state.value.homeTeam : state.value.visitorTeam
    })

    const getPlayerToThrow = computed((): SinglePlayer => {
        if (isTeam(state.value.homeTeam) && isTeam(state.value.visitorTeam)) {
            // Build player order
            let playerOrder: [SinglePlayer, SinglePlayer, SinglePlayer, SinglePlayer]
            if (state.value.bull_winner === GamePlayerSimple.HOME) {
                playerOrder = [
                    state.value.homeTeam.players[0],
                    state.value.visitorTeam.players[0],
                    state.value.homeTeam.players[1],
                    state.value.visitorTeam.players[1],
                ]
            } else {
                playerOrder = [
                    state.value.visitorTeam.players[0],
                    state.value.homeTeam.players[0],
                    state.value.visitorTeam.players[1],
                    state.value.homeTeam.players[1],
                ]
            }

            const currentLeg = getCurrentLeg.value
            const throwsInCurrentLeg = state.value.throws.filter((throwItem) => throwItem.leg === currentLeg)

            // The first player (bull winner) is always the first thrower.
            if (currentLeg === 1 && throwsInCurrentLeg.length === 0) {
                return playerOrder[0]
            }

            // If it is the first throw in a new leg, we take the next player.
            if (currentLeg > 1 && throwsInCurrentLeg.length === 0) {
                if (currentLeg % 2 === 0) {
                    return playerOrder[1]
                }
                return playerOrder[0]
            }

            // If it is the first throw for the other team in the current leg, we take the player after.
            const lastThrow = throwsInCurrentLeg[throwsInCurrentLeg.length - 1]

            if (lastThrow) {
                const lastThrowParticipantId = lastThrow.participant_id

                const isFirstThrowForOtherTeam = !throwsInCurrentLeg.find(
                    (throwItem) => throwItem.participant_id !== lastThrowParticipantId
                )
                if (isFirstThrowForOtherTeam) {
                    if (bullWinnerIsHome && currentLeg % 2 === 0) {
                        return playerOrder[0]
                    }
                    return playerOrder[1]
                }

                // If there are throws in the current leg, we take the next player.
                if (state.value.homeTeam.participant_id === lastThrow.participant_id) {
                    // A player of the visitor team should throw next.
                    const filteredList = throwsInCurrentLeg.filter(
                        (throwItem) => throwItem.participant_id === state.value.visitorTeam.participant_id
                    )
                    const previousTourPlayerId = filteredList[filteredList.length - 1]?.player_id
                    return state.value.visitorTeam.players.find(player => player.id !== previousTourPlayerId) as SinglePlayer
                } else {
                    // A player of the home team should throw next.
                    const filteredList = throwsInCurrentLeg.filter(
                        (throwItem) => throwItem.participant_id === state.value.homeTeam.participant_id
                    )
                    const previousTourPlayerId = filteredList[filteredList.length - 1]?.player_id
                    return state.value.homeTeam.players.find(player => player.id !== previousTourPlayerId) as SinglePlayer
                }
            }
        }

        const participant = getParticipantToThrow.value
        if (!isSinglePlayer(participant)) {
            throw new Error('Participant to throw is not a single player')
        }
        return participant
    })

    const getGamePlayerSimpleToThrow = computed((): GamePlayerSimple => {
        const playerToThrow = getParticipantToThrow.value
        if (playerToThrow === state.value.homeTeam) {
            return GamePlayerSimple.HOME
        }
        return GamePlayerSimple.VISITOR
    })

    const isOnThrow = (playerSimple: GamePlayerSimple): boolean => {
        return playerSimple === getGamePlayerSimpleToThrow.value
    }

    // @Todo: Refactor out this function.
    const getMostThrowsInCurrentLeg = computed((): number[] => {
        return getMostThrowsInLeg(
            getCurrentLeg.value,
            state.value.throws,
            getParticipantIdFor(GamePlayerSimple.HOME),
            getParticipantIdFor(GamePlayerSimple.VISITOR)
        )
    })

    const getRemainingScoreForParticipantOnThrow = computed(() => {
        const currentSimple = getGamePlayerSimpleToThrow.value
        return getRemainingScoreFor(currentSimple)
    })

    const scorePadIsGameShot = (): boolean => {
        if (scorePadValue.value === null) {
            return false
        }

        return (getRemainingScoreForParticipantOnThrow.value - scorePadValue.value) === 0
    }

    const addToScore = (n: number): Promise<void> => {
        let newScore
        if (scorePadValue.value === null) {
            newScore = n
        } else {
            newScore = parseInt(scorePadValue.value.toString() + n.toString())
        }

        if (getCorrectingScore.value && !isPossibleCorrection(newScore)) {
            console.log('Score correction is not possible')
            return Promise.reject(new Error('Score correction is not possible'))
        } else if (!getCorrectingScore.value && !isPossibleScore(newScore)) {
            return Promise.reject(new Error('Score is not possible'))
        }

        scorePadValue.value = newScore
        return Promise.resolve()
    }

    const isPossibleCorrection = (score: number): boolean => {
        if (score > 180) {
            return false
        }

        const correctingScore = getCorrectingScore.value

        if (!correctingScore) {
            return false
        }

        // This should be the the one for who we are correcting.
        const correctingFor: GamePlayerSimple = getPlayerSimpleForParticipantId(correctingScore.participant_id)

        const remainingScoreWithoutThrow = getRemainingScoreFor(correctingFor) + correctingScore.score

        if (score > remainingScoreWithoutThrow || remainingScoreWithoutThrow - score === 1) {
            return false
        }

        const impossibleScores = [163, 166, 169, 172, 173, 175, 176, 178, 179]

        if (impossibleScores.includes(score)) {
            return false
        }

        if (remainingScoreWithoutThrow === score) {
            return false
        }

        return true
    }

    const isPossibleScore = (score: number): boolean => {
        if (score > 180) {
            return false
        }

        const currentSimple = getGamePlayerSimpleToThrow.value

        const remainingScore = getRemainingScoreFor(currentSimple)

        if (score > remainingScore || remainingScore - score === 1) {
            return false
        }
        const impossibleScores = [163, 166, 169, 172, 173, 175, 176, 178, 179]

        if (impossibleScores.includes(score)) {
            return false
        }

        return true
    }

    const submitScore = (): Promise<DartsThrow> => {
        return new Promise((resolve, reject) => {
            if (scorePadValue.value === null) {
                return reject(new Error('No score to submit'))
            }

            const currentSimple = getGamePlayerSimpleToThrow.value

            if (getRemainingScoreFor(currentSimple) < 0) {
                return reject(new Error('Score would be lower than remaining score'))
            }


            const participantToThrow = getParticipantToThrow.value

            const remainingScore = getRemainingScoreFor(currentSimple)
            const isFinishingThrow = (remainingScore - scorePadValue.value) === 0

            if (isFinishingThrow) {
                $q.dialog(
                    {
                        component: ConfirmFinishingThrowModal,
                        componentProps: {
                            remainingScore,
                            minDartsUsed: determineMinDartsUsed(remainingScore),
                            playerName: getPlayerNameForParticipant(participantToThrow.participant_id),
                            onConfirm: (dartsUsed: number) => {
                                return resolve(registerThrowForScore(remainingScore, dartsUsed, true))
                            },
                            onClose: () => {
                                return reject(new Error('Score was not confirmed'))
                            },
                            onCancel: () => {
                                return reject(new Error('Score was not confirmed'))
                            }
                        },
                    })
            } else {
                return resolve(registerThrowForScore(scorePadValue.value, 3, false))
            }
        })
    }

    const submitScoreAsRemaining = (): Promise<DartsThrow> => {
        return new Promise((_, reject) => {
            if (
                scorePadValue.value === null ||
                scorePadValue.value === 0 ||
                (getRemainingScoreForParticipantOnThrow.value - scorePadValue.value) < 2
            ) {
                return reject(new Error('No score to submit'))
            }

            const newScore = getRemainingScoreForParticipantOnThrow.value - scorePadValue.value

            if (!isPossibleScore(newScore)) {
                throw new Error('Score is not possible')
            }

            scorePadValue.value = newScore

            return submitScore()
        })
    }

    const registerThrowForScore = (score: number, dartsUsed: number, finishingThrow: boolean): DartsThrow => {
        const currentSimple = getGamePlayerSimpleToThrow.value
        const playerOnThrow = getPlayerToThrow.value

        // Validate the throw is actually a finishing throw.
        const remainingScore = getRemainingScoreFor(currentSimple)
        if (remainingScore !== score && finishingThrow) {
            throw new Error('Throw is not a finishing throw')
        }

        const dartsThrow: DartsThrow = {
            player_id: playerOnThrow.id,
            participant_id: getParticipantIdFor(currentSimple),
            score: score,
            leg: getCurrentLeg.value,
            darts_used: dartsUsed,
            finishing_throw: finishingThrow,
            failed: false,
            ulid: ulid()
        }
        state.value.throws.push(dartsThrow)
        afterThrowCleanup()

        // Check if the game is concluded.
        if (onScoreSubmit.value) {
            onScoreSubmit.value(dartsThrow)
        }

        winner.value = getWinnerSimplePlayer.value

        if (onGameCompleted.value && winner.value) {
            onGameCompleted.value()
        }

        return dartsThrow
    }

    const updateFullThrow = (dartsThrow: DartsThrow): void => {
        const index = state.value.throws.findIndex(item => item.ulid === dartsThrow.ulid)
        if (index !== -1) {
            state.value.throws.splice(index, 1, dartsThrow)
        } else {
            console.warn(`Throw with ulid ${dartsThrow.ulid} not found.`)
        }
    }

    const updateThrow = ({failed, throwUlid}: { failed: boolean, throwUlid: string }): void => {
        const index = state.value.throws.findIndex(item => item.ulid === throwUlid)
        if (index !== -1) {
            state.value.throws[index].failed = failed
        } else {
            console.warn(`Throw with ulid ${throwUlid} not found.`)
        }
    }

    const concluded = computed(() => {
        // Check the throws for who has finishing throws equal to the amount of legs required.
        return homeTeamWon.value || visitorTeamWon.value
    })

    const homeTeamWon = computed(() => {
        if (state.value.legs_to_play) {
            const totalLegsPlayed = homeTeamLegs.value + visitorTeamLegs.value

            if (totalLegsPlayed === state.value.legs_to_play) {
                return homeTeamLegs.value > visitorTeamLegs.value
            }

            return false;
        }

        // Default implementation.
        return homeTeamLegs.value === getLegsToWin.value
    })

    const visitorTeamWon = computed(() => {
        if (state.value.legs_to_play) {
            const totalLegsPlayed = homeTeamLegs.value + visitorTeamLegs.value

            if (totalLegsPlayed === state.value.legs_to_play) {
                return homeTeamLegs.value < visitorTeamLegs.value
            }

            return false;
        }

        // Default implementation.
        return visitorTeamLegs.value === getLegsToWin.value
    })

    const getWinnerSimplePlayer = computed((): null | GamePlayerSimple => {
        if (homeTeamWon.value) {
            return GamePlayerSimple.HOME
        } else if (visitorTeamWon.value) {
            return GamePlayerSimple.VISITOR
        }

        return null
    })

    const homeTeamLegs = computed(() => {
        return getThrowsFor(GamePlayerSimple.HOME).filter(throwItem => throwItem.finishing_throw).length
    })

    const visitorTeamLegs = computed(() => {
        return getThrowsFor(GamePlayerSimple.VISITOR).filter(throwItem => throwItem.finishing_throw).length
    })

    const afterThrowCleanup = () => {
        scorePadValue.value = null
    }

    const undoBullWinner = () => {
        if (state.value.throws.length !== 0) {
            throw new Error('You can only undo the bull winner if there are no throws')
        }
        state.value.bull_winner = null
        if (onUndoBullWinner.value) {
            onUndoBullWinner.value()
        }
    }

    const removeLastThrow = () => {
        const throwToRemove = state.value.throws.pop()
        scorePadValue.value = null

        if (onUndoLastThrow.value && throwToRemove) {
            onUndoLastThrow.value(throwToRemove)
        }
    }

    const getPlayerNameForParticipant = (participantId: number | string): string => {
        if (state.value.homeTeam.participant_id === participantId) {
            return getHomePlayerName.value
        }
        return getVisitorPlayerName.value
    }

    const removeFromScore = () => {
        if (scorePadValue.value === null) {
            if (state.value.throws.length === 0) {
                if (settingCanUndoBullWinner.value) {
                    $q.dialog({
                        component: UndoBullWinnerModal,
                        componentProps: {
                            onConfirm: () => {
                                undoBullWinner()
                            }
                        },
                    })
                }
            } else {
                const lastThrow = state.value.throws[state.value.throws.length - 1]
                if (!lastThrow) {
                    return
                }

                if (!settingCanUndoLastThrow.value) {
                    return
                }

                $q.dialog({
                    component: UndoLastThrowModal,
                    componentProps: {
                        dartsThrow: lastThrow,
                        playerName: getPlayerNameForParticipant(lastThrow.participant_id),
                        onConfirm: () => {
                            removeLastThrow()
                        },
                    }
                })
            }
            return
        }

        // Convert the number to a string
        const numStr = scorePadValue.value.toString()

        // Check if the number has at least one digit
        if (numStr.length === 0) {
            scorePadValue.value = null
            return
        }

        // Remove the last digit
        const newNumStr = numStr.slice(0, -1)

        // Convert the new string back to a number
        const newNum = parseInt(newNumStr, 10)

        // Return the new number or null if the result is NaN
        scorePadValue.value = isNaN(newNum) ? null : newNum
    }

    const hasBullWinner = (): boolean => {
        return state.value.bull_winner !== null
    }

    const setBullWinner = (player: GamePlayerSimple): void => {
        state.value.bull_winner = player
        onBullWinner.value?.(getPlayerForSimple(player))
    }

    const getPlayerForSimple = (player: GamePlayerSimple): SomePlayer => {
        if (player === GamePlayerSimple.HOME) {
            return state.value.homeTeam
        }
        return state.value.visitorTeam
    }

    const determineMinDartsUsed = (score: number) => {
        if (score >= 99) {
            if ([100, 101, 104, 107, 110].includes(score)) {
                return [2, 3]
            }
            return [3]
        }

        if (score > 50) {
            return [2, 3]
        }

        if (score === 50) {
            return [1, 2, 3]
        }

        if (score < 50 && score > 40) {
            return [2, 3]
        }

        if (score <= 40) {
            if (score % 2 === 0) {
                return [1, 2, 3]
            }
            return [2, 3]
        }
        return [3]
    }

    const startScoreCorrection = (dartsThrow: DartsThrow): void => {
        console.log('Starting score correction')
        correctingScore.value = dartsThrow
    }

    const cancelCorrectingScore = (): void => {
        correctingScore.value = null
    }

    const getCorrectingScore = computed((): DartsThrow | null => {
        return correctingScore.value
    })

    const isCorrectingScore = (dartsThrow: DartsThrow): boolean => {
        if (!correctingScore.value) {
            return false
        }
        return correctingScore.value.ulid === dartsThrow.ulid
    }

    const correctScore = (): Promise<DartsThrow> => {
        return new Promise((resolve, reject) => {
            const correctingScoreToChange = getCorrectingScore.value
            if (!correctingScoreToChange) {
                return reject(new Error('No score to correct'))
            }

            if (!scorePadValue.value) {
                return reject(new Error('No score to submit'))
            }

            const dartsThrow = state.value.throws.find((throwItem) => throwItem.ulid === correctingScoreToChange.ulid)
            if (!dartsThrow) {
                return reject(new Error('Score to correct not found'))
            }

            dartsThrow.score = scorePadValue.value

            correctingScore.value = null
            scorePadValue.value = null

            if (onUpdateThrow.value) {
                onUpdateThrow.value(dartsThrow)
            }

            return resolve(dartsThrow)
        })
    }

    const isLegsToPlay = (): boolean => {
        return state.value.legs_to_play !== null && state.value.legs_to_play !== undefined
    }

    const getLegsToPlay = computed((): number | null => {
        if (state.value.legs_to_play === undefined || state.value.legs_to_play === null) {
            return null;
        }
        return state.value.legs_to_play
    })

    const getLegsToWin = computed((): number | null => {
        if (state.value.legs_to_play || state.value.legs_to_win === null) {
            return null;
        }
        return state.value.legs_to_win
    })

    const getBestOf = computed((): number | null => {
        if (state.value.legs_to_play || state.value.legs_to_win === null) {
            return null;
        }
        return state.value.legs_to_win * 2 - 1
    })

    const getStartingScore = computed((): number => {
        return state.value.starting_score
    })

    const getBullWinner = computed((): string => {
        if (state.value.bull_winner === GamePlayerSimple.HOME) {
            return getHomePlayerName.value
        } else {
            return getVisitorPlayerName.value
        }
    })

    const bullWinnerIsHome = computed(() => {
        return state.value.bull_winner === GamePlayerSimple.HOME
    })

    const toggleShowAverages = (): void => {
        settings.value.showAverages = !settings.value.showAverages
    }

    const shouldShowAverages = computed(() => {
        return settings.value.showAverages
    })

    const reset = (): void => {
        winner.value = null
        correctingScore.value = null
        scorePadValue.value = null
        state.value = {} as LocalGameStore
        tabletGame.value = null
        throwQueue.value = []
        onReset.value?.()
    }

    const hasWriter = computed(() => {
        return state.value.writer !== null && state.value.writer !== undefined
    })

    const getWriterName = computed(() => {
        if (state.value.writer === null || state.value.writer === undefined) {
            return 'TBD'
        }
        if (isTeam(state.value.writer)) {
            return state.value.writer.players[0].name + ' - ' + state.value.writer.players[1].name
        }

        return state.value.writer.name
    })

    const getThrow = (ulid: string): DartsThrow | undefined => {
        return state.value.throws.find(throwItem => throwItem.ulid === ulid)
    }

    return {
        // Core states.
        state: skipHydrate(state),
        settings: skipHydrate(settings),
        onlineGame: skipHydrate(onlineGame),
        tabletGame: skipHydrate(tabletGame),
        throwQueue: skipHydrate(throwQueue),
        showScorePad,
        scorePadValue,
        correctingScore,
        winner,
        settingCanCorrectScore,
        settingCanSetBullWinner,
        settingCanUndoBullWinner,
        settingCanUndoLastThrow,
        // Getters
        concluded,
        isInitialized,
        getPlayerToThrow,
        shouldShowAverages,
        homeTeamLegs,
        visitorTeamLegs,
        isLegsToPlay,
        getLegsToPlay,
        getLegsToWin,
        getBestOf,
        getWinnerSimplePlayer,
        getGamePlayerSimpleToThrow,
        getBullWinner,
        getStartingScore,
        getCorrectingScore,
        getCurrentLeg,
        getRemainingScoreForParticipantOnThrow,
        hasWriter,
        getWriterName,
        // Functions.
        getMostThrowsInCurrentLeg,
        initialize,
        getPlayerNameFor,
        getDartsThrownFor,
        getLegsWonFor,
        getRemainingScoreFor,
        getCurrentLegThrowsFor,
        getLegWinner,
        getThrowsFor,
        addToScore,
        submitScore,
        isOnThrow,
        hasBullWinner,
        setBullWinner,
        scorePadIsGameShot,
        submitScoreAsRemaining,
        removeFromScore,
        startScoreCorrection,
        isCorrectingScore,
        cancelCorrectingScore,
        correctScore,
        toggleShowAverages,
        getLegAverage,
        getGameAverage,
        reset,
        updateThrow,
        updateFullThrow,
        getThrow,
        getFirst9Average,
        get180sFor,
        get140sFor,
        getHighCheckoutsFor,
        getPlayersFor,
        getPlayerFor,
        bullWinnerIsHome,
        sync,
        // Callbacks
        onScoreSubmit,
        onBullWinner,
        onReset,
        onUndoLastThrow,
        onUpdateThrow,
        onGameCompleted,
        onUndoBullWinner,
        onSync,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(localGameStore, import.meta.hot))
}
