<script setup lang="ts">

import {onKeyDown} from '@vueuse/core'

const emit = defineEmits([
  'confirm',
  'cancel',
  ...useDialogPluginComponent.emits,
])

const {dialogRef, onDialogHide, onDialogOK, onDialogCancel} = useDialogPluginComponent()

const props = defineProps<{
  playerName: string,
  remainingScore: number,
  minDartsUsed: number[]
}>()

function confirm(dartsUsed: number) {
  emit('confirm', dartsUsed)
  onDialogOK()
}

function cancel() {
  emit('cancel')
  onDialogCancel()
}

onKeyDown('Enter', (e) => {
  e.stopPropagation()
  e.preventDefault()
});

onKeyDown('Backspace', () => {
  cancel()
})

onKeyDown('1', () => {
  if (props.minDartsUsed.find(item => item === 1)) {
    confirm(1)
  }
})
onKeyDown('2', () => {
  if (props.minDartsUsed.find(item => item === 2)) {
    confirm(2)
  }
})
onKeyDown('3', () => {
  if (props.minDartsUsed.find(item => item === 3)) {
    confirm(3)
  }
})

</script>

<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard>
      <QCardSection>
        <div class="space-y-2 text-4xl">
          <p>{{ $t('Game shot!') }}</p>
          <p>
            {{
              $t('Please confirm how many darts were used to finish {remainingScore} by {playerName}', {
                remainingScore,
                playerName
              })
            }}
          </p>
          <div class="grid grid-cols-3 gap-1 h-20">
            <template v-for="dart in minDartsUsed" :key="dart">
              <QBtn
                v-touch:tap.prevent="() => confirm(dart)"
                color="secondary"
                size="xl"
                :label="dart.toString()"
              />
            </template>
          </div>
          <div class="flex flex-row justify-between pt-8">
            <QBtn
              v-touch:tap.prevent="cancel"
              color="negative"
              size="xl"
            >
              {{ $t('Cancel') }}
            </QBtn>
          </div>
        </div>
      </QCardSection>
    </QCard>
  </QDialog>
</template>
