<script setup lang="ts">
import type {DartsThrow} from '~/store/localGameStore'
import {onKeyDown} from '@vueuse/core'

const emit = defineEmits(['confirm', ...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogCancel } = useDialogPluginComponent()

onKeyDown('Enter', (e) => {
  e.preventDefault()
  e.stopPropagation()
  emit('confirm')
  onDialogCancel()
})

onKeyDown('Backspace', () => {
  onDialogCancel()
})

const props = defineProps<{
  dartsThrow: DartsThrow,
  playerName: string,
}>()

function confirm() {
  emit('confirm')
  onDialogCancel()
}
</script>

<template>
  <QDialog ref="dialogRef" @hide="onDialogHide">
    <QCard>
      <QCardSection>
        <div class="space-y-2 text-4xl">
          <p>{{ $t('Remove last throw of {amount} by {playerName}', {amount: props.dartsThrow.score, playerName}) }}</p>
          <div class="flex flex-row justify-between">
            <QBtn
              v-touch:tap.prevent="confirm"
              color="positive"
              size="xl"
            >
              {{ $t('Yes') }}
            </QBtn>
            <QBtn
              v-touch:tap.prevent="() => onDialogCancel()"
              color="negative"
              size="xl"
            >
              {{ $t('No') }}
            </QBtn>
          </div>
        </div>
      </QCardSection>
    </QCard>
  </QDialog>
</template>
